/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { debounce } from "lodash";

import {
  FiArrowLeft,
  FiAtSign,
  FiBarChart2,
  FiCoffee,
  FiCompass,
  FiFeather,
  FiMap,
  FiPhone,
  FiSettings,
  FiUser,
  FiLifeBuoy,
} from "react-icons/fi";
import {
  BsCardHeading,
  BsClock,
  BsFillCartFill,
  BsFillTrashFill,
} from "react-icons/bs";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";
import { useSidebar } from "../../hooks/SidebarContext";
import api from "../../services/api";

import Sidebar from "../../components/Sidebar";
import Input from "../../components/Input";
import Button from "../../components/Button";
import LoadingSpinner from "../../components/LoadingSpinner";
import Select from "../../components/ReactSelect";
import InvisibleInput from "../../components/InvisibleInput";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";
import AutocompleteInput from "../../components/AutocompleteInput";
import getCompaniesValidationErrors from "../../utils/getCompaniesValidationErrors";

import {
  cepFormat,
  cpfOrCnpjFormat,
  onChangePhoneMask,
  phoneFormat,
  onChangeTimeMask,
} from "../../utils/inputAndTextMasks";

import { BlockedDeliveryman, Company } from "../../@types/customTypes";

import * as Styled from "./styles";
import {
  generateSearchVariations,
  normalizePhoneNumber,
  normalizeString,
} from "../../utils/fomartSearchs";
import { DeliverymansResponse } from "../Deliverymans";

interface CompanyStatusResponse {
  id: number;
  name: string;
}

interface UpdateEstablishmentFormData {
  social_name: string;
  fantasy_name: string;
  status: string;
  segment: string;
  phones: string;
  doc: string;
  state_register: string;
  city_register: string;
  postal_code_1: string;
  city_1: string;
  uf_1: string;
  latitude_1: string;
  longitude_1: string;
  address_1: string;
  number_1: string;
  complement: string;
  neighborhood_1: string;
  email: string;
  company_role: string;
  name: string;
  responsible_phone: string;
  config_run_delivery_creation_time: string;
  config_run_delivery_creation_active: boolean;
  max_ifood_integrations: number;
  integrations_rd_enabled: boolean;
  max_simultaneous_deliveries: number;
}

const EstablishmentsById: React.FC = () => {
  const [deliverymans, setDeliverymans] = useState<BlockedDeliveryman[]>(
    [] as BlockedDeliveryman[],
  );
  const [company, setCompany] = useState<Company | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [segments, setSegments] = useState([{ label: "", value: 0 }]);
  const [companyStatus, setCompanyStatus] = useState([{ label: "", value: 0 }]);
  const [initialStatusId, setInitialStatusId] = useState(0);
  const [deliverymansBlocks, setDelivermansBlocks] = useState<
    BlockedDeliveryman[]
  >([]);
  const [initialAdress, setInitialAddress] = useState<{
    address: null | string;
    number: null | string;
    complement: null | string;
  }>({
    address: null,
    number: null,
    complement: null,
  });

  const { isSidebarMaximized } = useSidebar();
  const params: { id: string } = useParams();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  const handleGetDeliverymans = useCallback(
    async (size = 8, page = 1, searchQuery = "") => {
      try {
        setIsLoading(true);
        const searchValue = normalizeString(searchQuery);
        const searchVariations = generateSearchVariations(searchValue);
        const normalizedPhone = normalizePhoneNumber(searchValue);

        const userNameSearchQuery = searchVariations
          .map((variation) => `user.name:${variation}`)
          .join(";");

        const finalSearchQuery = `${userNameSearchQuery};user.email:${searchValue};phones.number:${normalizedPhone};cpf:${normalizedPhone}`;

        const { data: deliverymansResponse } =
          await api.get<DeliverymansResponse>(
            `deliverymans?page[size]=${size}&page[number]=${page}&search=${finalSearchQuery}`,
          );

        setDeliverymans(
          deliverymansResponse.data.map((d) => ({
            deliveryman_name: d.nickname,
            deliveryman_id: d.id,
          })),
        );
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      } finally {
        setIsLoading(false);
      }
    },
    [addToast],
  );

  const searchDeliveryman = debounce((value: string) => {
    handleGetDeliverymans(8, 1, value);
  }, 500);

  const handleRemoveFromBlockList = async (deliverymanId: number) => {
    try {
      setShowSpinner(true);
      await api.delete(`/companies/blocklist/remove`, {
        data: { company_id: params.id, deliveryman_id: deliverymanId },
      });
      const { data: companyDataResponse }: { data: Company } = await api.get(
        `companies/${params.id}`,
      );

      await setCompany(companyDataResponse);
      await setDelivermansBlocks(companyDataResponse.blockLists);
      await setDeliverymans([]);
      addToast("Entregador removido da lista de bloqueio com sucesso!", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast("Erro ao remover entregador da lista de bloqueio.", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setShowSpinner(false);
    }
  };

  const handleAddToBlockList = async (deliverymanId: number) => {
    try {
      setShowSpinner(true);
      await api.post(`/companies/blocklist/add`, {
        company_id: params.id,
        deliveryman_id: deliverymanId,
      });

      const { data: companyDataResponse }: { data: Company } = await api.get(
        `companies/${params.id}`,
      );

      await setCompany(companyDataResponse);
      await setDelivermansBlocks(companyDataResponse.blockLists);
      await setDeliverymans([]);
      addToast("Entregador adicionado à lista de bloqueio com sucesso!", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      addToast("Erro ao adicionar entregador à lista de bloqueio.", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setShowSpinner(false);
    }
  };

  const handleRemoveBlock = useCallback(
    async (deliverymanId, remove?) => {
      const { isConfirmed } = await Swal.fire({
        title: `${remove ? "Remover" : "Adicionar"} entregador da lista`,
        html: `Tem certeza que deseja ${
          remove ? "remover" : "adicionar"
        } o entregador da lista de bloqueados?`,
        icon: remove ? "error" : "success",
        showCancelButton: true,
        confirmButtonColor: "var(--color-primary)",
        cancelButtonColor: "var(--color-danger)",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      });

      if (isConfirmed) {
        if (remove) {
          await handleRemoveFromBlockList(deliverymanId);
        } else {
          await handleAddToBlockList(deliverymanId);
        }
      }
    },
    [addToast],
  );

  const handleResetPass = useCallback(async () => {
    const { isConfirmed } = await Swal.fire({
      title: "Resetar senha",
      html: `Tem certeza que deseja resetar a senha?`,
      icon: "error",
      showCancelButton: true,
      confirmButtonColor: "var(--color-primary)",
      cancelButtonColor: "var(--color-danger)",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    });

    if (isConfirmed) {
      setIsLoading(true);
      try {
        const data = {
          email: company?.responsibles[0].user.email,
          type: "companies",
        };
        await api.post(`/password/default_reset`, data);

        addToast("Senha resetada com sucesso", {
          appearance: "success",
          autoDismiss: true,
        });
      } catch (e) {
        addToast("Erro ao resetar senha", {
          appearance: "error",
          autoDismiss: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }, [addToast, company?.responsibles]);

  const submitCompanyUpdateForm = useCallback(
    async (data: UpdateEstablishmentFormData) => {
      try {
        data.phones = data.phones.replace(/\D/g, "");
        data.doc = data.doc.replace(/\D/g, "");
        data.postal_code_1 = data.postal_code_1.replace(/\D/g, "");

        const dataToUpdateCompany = {
          social_name: data.social_name,
          fantasy_name: data.fantasy_name,
          doc: data.doc,
          company_phone: Array.isArray(data.phones) ? [data.phones] : [],
          state_register: data.state_register,
          city_register: data.city_register,
          segment_id: data.segment,
          max_ifood_integrations: data.max_ifood_integrations,
          max_simultaneous_deliveries: data.max_simultaneous_deliveries,
          responsible: {
            company_role: data.company_role,
            user: {
              email: data.email,
              name: data.name,
            },
            phones: [data.responsible_phone.replace(/\D/g, "")],
          },
          config_run_delivery_creation: {
            time: data.config_run_delivery_creation_time,
            active: data.config_run_delivery_creation_active,
          },
          integrations_rd: {
            enabled: data.integrations_rd_enabled,
          },
        };

        const dataToUpdateCompanyAdress = {
          address: data.address_1,
          number: data.number_1,
          complement: data.complement,
          neighborhood: data.neighborhood_1,
          postal_code: data.postal_code_1,
          uf: data.uf_1,
          city: data.city_1,
          latitude: data.latitude_1,
          longitude: data.longitude_1,
        };

        await api.put(`/companies/${params.id}`, dataToUpdateCompany);

        if (
          initialAdress.address !== data.address_1 ||
          initialAdress.complement !== data.complement ||
          initialAdress.number !== data.number_1
        ) {
          await api.put(`companies/address/${params.id}`, {
            address: dataToUpdateCompanyAdress,
          });
        }

        if (initialStatusId.toString() !== data.status) {
          await api.put(`companies/${params.id}/status`, {
            company_status_id: data.status,
          });
        }

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Alterações realizadas com sucesso!",
        });
      } catch (error: any) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Falha ao atualizar, ${
            Object.values(JSON.parse(error.response.request.responseText)).join(
              " ",
            ) || error.message
          }`,
        });
      }
      setShowSpinner(false);
      setIsLoading(false);
    },
    [params.id, initialStatusId, initialAdress],
  );

  const verifyFormSubmit = useCallback(
    async (data: UpdateEstablishmentFormData) => {
      setShowSpinner(true);
      setIsLoading(true);
      try {
        const schema = Yup.object().shape({
          social_name: Yup.string().min(3, "Razão social inválida"),
          fantasy_name: Yup.string().min(3, "Nome fantasia inválido"),
          phones: Yup.string().min(14, "Campo obrigatório"),
          responsible_phone: Yup.string().min(14, "Campo obrigatório"),
          doc: Yup.string().min(14, "Campo obrigatório"),
          latitude_1: Yup.string().required(),
          longitude_1: Yup.string().required(),
          number_1: Yup.string().required("Campo obrigatório"),
          name: Yup.string().required("Campo obrigatório"),
          email: Yup.string()
            .email("E-mail inválido")
            .required("Campo obrigatório"),
          company_role: Yup.string().required("Campo obrigatório"),
        });
        await schema.validate(data, { abortEarly: false });
        submitCompanyUpdateForm(data);
      } catch (err) {
        setShowSpinner(false);
        setIsLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getCompaniesValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      }
    },
    [submitCompanyUpdateForm],
  );

  const onBlurMaskDoc = useCallback((e: React.FocusEvent<HTMLInputElement>) => {
    e.target.value = cpfOrCnpjFormat(e.target.value);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { data: companyStatusData } = await api.get("/companystatus");
        const MappedCompanyStatus = companyStatusData.map(
          (status: CompanyStatusResponse) => {
            return {
              label: status.name,
              value: status.id,
            };
          },
        );
        setCompanyStatus(MappedCompanyStatus);

        const { data: segmentsData } = await api.get("/segments");
        const MappedSegments = segmentsData.map(
          (type: { name: string; id: number }) => {
            return {
              label: type.name,
              value: type.id,
            };
          },
        );
        setSegments(MappedSegments);

        const { data: companyDataResponse }: { data: Company } = await api.get(
          `companies/${params.id}`,
        );

        setCompany(companyDataResponse);
        setDelivermansBlocks(companyDataResponse.blockLists);

        setInitialStatusId(companyDataResponse.status.id);
        setInitialAddress({
          address: companyDataResponse.address.address,
          number: companyDataResponse.address.number || "",
          complement: companyDataResponse.address.complement || "",
        });

        formRef.current?.setData({
          social_name: companyDataResponse.social_name,
          fantasy_name: companyDataResponse.fantasy_name,
          doc: cpfOrCnpjFormat(companyDataResponse.doc),
          phones: companyDataResponse.phones[0]
            ? phoneFormat(companyDataResponse.phones[0])
            : "",
          segment: companyDataResponse.segment.id,
          status: companyDataResponse.status.id,
          state_register: companyDataResponse.state_register,
          city_register: companyDataResponse.city_register,
          autocomplete_1: `${companyDataResponse.address.address}${
            companyDataResponse.address.number &&
            `, ${companyDataResponse.address.number} - ${companyDataResponse.address.neighborhood}, ${companyDataResponse.address.city} - ${companyDataResponse.address.uf}`
          }`,
          address_1: companyDataResponse.address.address,
          number_1: companyDataResponse.address.number,
          complement: companyDataResponse.address.complement || "",
          neighborhood_1: companyDataResponse.address.neighborhood,
          postal_code_1: cepFormat(companyDataResponse.address.postal_code),
          uf_1: companyDataResponse.address.uf,
          city_1: companyDataResponse.address.city,
          latitude_1: companyDataResponse.address.latitude,
          longitude_1: companyDataResponse.address.longitude,
          company_role: companyDataResponse.responsibles[0].company_role,
          name: companyDataResponse.responsibles[0].user.name,
          email: companyDataResponse.responsibles[0].user.email,
          responsible_phone: phoneFormat(
            companyDataResponse.responsibles[0].phones[0],
          ),
          config_run_delivery_creation_time:
            companyDataResponse.configRunDeliveryCreation
              ? companyDataResponse.configRunDeliveryCreation.time
              : "00:00:00",
          config_run_delivery_creation_active:
            companyDataResponse.configRunDeliveryCreation
              ? companyDataResponse.configRunDeliveryCreation.active
              : false,
          max_ifood_integrations: companyDataResponse.max_ifood_integrations,
          max_simultaneous_deliveries:
            companyDataResponse.max_simultaneous_deliveries,
          integrations_rd_enabled: companyDataResponse.integrations_rd
            ? companyDataResponse.integrations_rd.enabled
            : false,
        });
      } catch (e) {
        addToast("Falha ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    })();
  }, [params.id, addToast]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.CompanyContainer>
          <Styled.Link to="/gerenciar/estabelecimentos">
            <FiArrowLeft size={20} />
            Voltar
          </Styled.Link>
          <Styled.Form
            onSubmit={verifyFormSubmit}
            ref={formRef}
            autoComplete="off"
          >
            <Styled.Strong>Dados do estabelecimento</Styled.Strong>
            <Styled.Row>
              <Input
                type="text"
                id="social_name"
                name="social_name"
                placeholder="Razão social"
                icon={FiFeather}
              />
              <Input
                type="text"
                id="fantasy_name"
                name="fantasy_name"
                placeholder="Nome fantasia"
                icon={FiUser}
              />

              <Select
                id="status"
                name="status"
                placeholder="Status"
                formRef={formRef}
                options={companyStatus}
                isSearchable={false}
                icon={FiBarChart2}
              />
            </Styled.Row>
            <Styled.Row>
              <Select
                id="segment"
                name="segment"
                placeholder="Segmento"
                formRef={formRef}
                options={segments}
                icon={FiCoffee}
                isSearchable={false}
              />
              <Input
                type="text"
                id="phones"
                name="phones"
                placeholder="Telefone"
                onChange={onChangePhoneMask}
                icon={FiPhone}
                maxLength={15}
              />
              <Input
                type="text"
                id="doc"
                name="doc"
                placeholder="CPF/CNPJ"
                icon={BsCardHeading}
                onBlur={onBlurMaskDoc}
                maxLength={14}
              />
            </Styled.Row>
            <Styled.Row>
              <AutocompleteInput
                type="text"
                id="autocomplete_1"
                name="autocomplete_1"
                index={1}
                placeholder="Endereço"
                icon={FiCompass}
                formRef={formRef}
              />
              <Input
                type="text"
                id="complement"
                name="complement"
                placeholder="Complemento"
                icon={FiMap}
              />
              <InvisibleInput type="text" id="address_1" name="address_1" />
              <InvisibleInput type="text" id="latitude_1" name="latitude_1" />
              <InvisibleInput type="text" id="longitude_1" name="longitude_1" />
              <InvisibleInput
                type="text"
                id="postal_code_1"
                name="postal_code_1"
              />
              <InvisibleInput type="text" id="number_1" name="number_1" />
              <InvisibleInput
                type="text"
                id="neighborhood_1"
                name="neighborhood_1"
              />
              <InvisibleInput type="text" id="city_1" name="city_1" />
              <InvisibleInput type="text" id="uf_1" name="uf_1" />
            </Styled.Row>
            <Styled.Row>
              <Input
                type="text"
                id="state_register"
                name="state_register"
                placeholder="Inscrição Estadual"
                icon={BsCardHeading}
                maxLength={9}
              />
              <Input
                type="text"
                id="city_register"
                name="city_register"
                placeholder="Inscrição Municipal"
                icon={BsCardHeading}
                maxLength={11}
              />
            </Styled.Row>
            <Styled.Row>
              <Input
                type="text"
                id="max_ifood_integrations"
                name="max_ifood_integrations"
                placeholder="Limite de integrações com ifood"
                icon={BsFillCartFill}
                maxLength={2}
              />
            </Styled.Row>

            <Styled.Strong>Dados do responsável</Styled.Strong>
            <Styled.Row>
              <Select
                id="company_role"
                name="company_role"
                placeholder="Cargo"
                formRef={formRef}
                isSearchable={false}
                options={[
                  {
                    label: "companyAdmin",
                    value: "companyAdmin",
                  },
                ]}
                icon={FiSettings}
              />
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Nome"
                icon={FiUser}
              />
            </Styled.Row>

            <Styled.Row>
              <Input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                icon={FiAtSign}
              />

              <Input
                type="text"
                id="responsible_phone"
                name="responsible_phone"
                onChange={onChangePhoneMask}
                placeholder="Telefone"
                icon={FiPhone}
              />
            </Styled.Row>
            <Styled.Strong>Auto despacho</Styled.Strong>
            <Styled.Row>
              <Select
                id="config_run_delivery_creation_active"
                name="config_run_delivery_creation_active"
                placeholder="Ativar"
                formRef={formRef}
                isSearchable={false}
                options={[
                  {
                    label: "Não",
                    value: false,
                  },
                  {
                    label: "Sim",
                    value: true,
                  },
                ]}
                icon={FiSettings}
              />
              <Input
                type="text"
                id="config_run_delivery_creation_time"
                name="config_run_delivery_creation_time"
                placeholder="Tempo"
                onChange={onChangeTimeMask}
                icon={BsClock}
              />
            </Styled.Row>

            <Styled.Strong>Integração com Droga Raia Brasil</Styled.Strong>
            <Styled.Row>
              <Select
                id="integrations_rd_enabled"
                name="integrations_rd_enabled"
                placeholder="Ativar"
                formRef={formRef}
                isSearchable={false}
                options={[
                  {
                    label: "Não",
                    value: false,
                  },
                  {
                    label: "Sim",
                    value: true,
                  },
                ]}
                icon={FiLifeBuoy}
              />
            </Styled.Row>

            <Styled.Strong>Gerenciar Entregadores</Styled.Strong>
            <div
              style={{
                display: "flex",
                paddingLeft: "40px",
                paddingRight: "40px",
                gap: 14,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  gap: 30,
                  flexDirection: "column",
                }}
              >
                <Select
                  formRef={formRef}
                  icon={BsFillCartFill}
                  id="deliveryman_search"
                  name="deliveryman_search"
                  placeholder="Buscar entregador para bloquear"
                  defaultValue=""
                  onInputChange={(value: string) => searchDeliveryman(value)}
                  options={deliverymans.map((d) => ({
                    label: `${d.deliveryman_id} - ${d.deliveryman_name}`,
                    value: d.deliveryman_id,
                  }))}
                  onChange={(selected: any) =>
                    handleRemoveBlock(selected?.value)
                  }
                  isLoading={isLoading}
                  isSearchable
                />
                <Input
                  type="text"
                  id="max_simultaneous_deliveries"
                  name="max_simultaneous_deliveries"
                  placeholder="Limite de entregas dos entregadores"
                  icon={BsFillCartFill}
                  maxLength={2}
                />
              </div>
              <div
                style={{
                  width: "30%",
                  border: "1px solid rgba(220, 220, 220)",
                  borderRadius: "5px",
                  padding: 6,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                    paddingBottom: "8px",
                    borderBottom: "1px solid rgba(220, 220, 220)",
                    marginBottom: "8px",
                  }}
                >
                  <span>ID</span>
                  <span>Nickname</span>
                  <span>Remover</span>
                </div>

                <div style={{ maxHeight: "200px", overflow: "auto" }}>
                  {deliverymansBlocks.map((blocked) => (
                    <Styled.Row
                      key={blocked.deliveryman_id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "4px 0",
                        borderBottom: "1px solid rgba(220, 220, 220)",
                      }}
                    >
                      <span>{blocked.deliveryman_id}</span>
                      <span>{blocked.deliveryman_name}</span>
                      <BsFillTrashFill
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() =>
                          handleRemoveBlock(blocked.deliveryman_id, true)
                        }
                      />
                    </Styled.Row>
                  ))}
                </div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                type="button"
                onClick={handleResetPass}
                customColor="#ff9300"
                content={isLoading ? "Carregando..." : "Resetar senha"}
                disabled={isLoading}
              />
              <Button
                type="submit"
                content={isLoading ? "Carregando..." : "Salvar dados"}
                disabled={isLoading}
              />
            </div>
          </Styled.Form>
        </Styled.CompanyContainer>
      </ContainerMain>
    </Container>
  );
};

export default EstablishmentsById;
