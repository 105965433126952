/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import {
  FiArrowLeft,
  FiAtSign,
  FiBarChart2,
  FiBookmark,
  FiCalendar,
  FiColumns,
  FiCompass,
  FiLock,
  FiMap,
  FiNavigation,
  FiPhone,
  FiUnlock,
  FiUser,
  FiUsers,
} from "react-icons/fi";
import { GiIdCard, GiKeyCard, GiMusicalKeyboard } from "react-icons/gi";
import { AiOutlineCar } from "react-icons/ai";
import { RiMotorbikeFill, RiUserHeartLine } from "react-icons/ri";
import { BsCardHeading } from "react-icons/bs";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";

import { useSidebar } from "../../hooks/SidebarContext";

import api from "../../services/api";

import Sidebar from "../../components/Sidebar";
import Input from "../../components/Input";
import Button from "../../components/Button";
import LoadingSpinner from "../../components/LoadingSpinner";
import Select from "../../components/ReactSelect";
import Container from "../../components/Container";
import ContainerMain from "../../components/ContainerMain";
import AutocompleteInput from "../../components/AutocompleteInput";
import InvisibleInput from "../../components/InvisibleInput";
import getValidationErrors from "../../utils/getValidationErrors";

import {
  onChangeCpfMask,
  onChangeDateMask,
  onChangePhoneMask,
  onChangeRgMask,
} from "../../utils/inputAndTextMasks";

import * as Styled from "./styles";

interface StateResponse {
  id: number;
  name: string;
  uf: string;
}

interface DeliverymanStatusResponse {
  id: number;
  name: string;
}

interface VehicleTypesResponse {
  id: number;
  name: string;
}

interface CitiesResponse {
  id: number;
  state_id: number;
  name: string;
}

interface User {
  name: string;
  email: string;
  password: string;
}

interface Address {
  postal_code: string;
  address: string;
  number: string;
  city: string;
  uf: string;
}

interface DeliverymanFormData {
  nickname: string;
  status: string;
  phone: string;
  state_id: string;
  cities: string | string[];
  email: string;
  plate: string | null;
  renavam: string | null;
  name: string;
  mother_name: string;
  expiration_cnh_date: string | null;
  birthday: string;
  address_1: string;
  number_1: string;
  complement: string;
  neighborhood_1: string;
  postal_code_1: string;
  quantity_deliveries: number;
  uf_1: string;
  city_1: string;
  latitude_1: string;
  longitude_1: string;
  cpf: string;
  has_bag: boolean;
  user: User;
  password: string;
  address: Address;
  vehicle_type_id: number;
  vehicle: {
    vehicle_type_id: number;
    plate: string | null;
    renavam: string | null;
  };
  cnh: string | null;
  rg: string;
}

const DeliverymansNew: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSpinner, setShowSpinner] = useState(true);
  const [childrenStateUpdater, setChildrenStateUpdater] = useState(0);
  const [states, setStates] = useState([{ label: "", value: "" }]);
  const [cities, setCities] = useState([{ label: "", value: "" }]);
  const [deliverymanStatus, setDeliverymanStatus] = useState([
    { label: "", value: "" },
  ]);
  const [vehicleTypes, setVehicleTypes] = useState([{ label: "", value: "" }]);
  const [showVehicleDetails, setShowVehicleDetails] = useState(true);

  const { isSidebarMaximized } = useSidebar();
  const params: { id: string } = useParams();
  const { addToast } = useToasts();
  const formRef = useRef<FormHandles>(null);

  const handleShowVehicleDetails = useCallback((vehicleTypeId: number) => {
    if (!vehicleTypeId) {
      return;
    }

    setShowVehicleDetails(vehicleTypeId < 3);
  }, []);

  const getCities = useCallback(async (state_id: number, isUpdate = true) => {
    if (!state_id) {
      return;
    }

    if (isUpdate) {
      setChildrenStateUpdater((state) => state + 1);
    }

    setShowSpinner(true);
    setCities([{ label: "", value: "" }]);
    formRef.current?.setData({
      cities: [],
    });
    const { data: CitiesData } = await api.get(`/cities/${state_id}`);
    const MappedCities = CitiesData.map((city: CitiesResponse) => {
      return {
        label: city.name,
        value: city.id,
      };
    });
    setCities(MappedCities);

    if (isUpdate) {
      setShowSpinner(false);
    }
  }, []);

  // eslint-disable-next-line consistent-return
  const submitDeliverymanCreateForm = useCallback(
    async (data: DeliverymanFormData) => {
      try {
        // Formatar o telefone
        data.phone = data.phone.replace(/\D/g, ""); // Remove todos os caracteres não numéricos

        // Adicionar o campo `phones` (certifique-se de que este é o formato esperado pelo backend)
        const phoneArray = [data.phone];
        const payload = {
          ...data,
          phones: phoneArray,
          withdrawal_blocked: false,
        };

        const response = await api.post("deliverymans", payload);

        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Entregador cadastrado com sucesso!",
        });

        window.location.href = `/gerenciar/entregadores/${response.data.id}`;
        return response.data;
      } catch (error: any) {
        // Verifica se o erro tem uma resposta e trata adequadamente
        const errorMessage = error.response
          ? Object.values(
              JSON.parse(error.response.request?.responseText),
            ).join(" ")
          : error.message ||
            "Ocorreu um erro inesperado. Por favor, tente novamente.";

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `Falha ao cadastrar, ${errorMessage}`,
        });
      }
    },
    [],
  );

  const verifyFormSubmit = useCallback(
    async (data: DeliverymanFormData) => {
      setShowSpinner(true);
      setIsLoading(true);
      try {
        data.phone = data.phone.replace(/\D/g, ""); // Remove caracteres não numéricos
        data.birthday = data.birthday.replace(/\D/g, "-"); // Remove caracteres não numéricos da data de nascimento

        if (data.expiration_cnh_date) {
          data.expiration_cnh_date = data.expiration_cnh_date?.replace(
            /\D/g,
            "-",
          );
        }
        data.rg = data.rg.replace(/\D/g, "").slice(0, 10);
        data.cpf = data.cpf.replace(/\D/g, "").substring(0, 11); // Limita o CPF a 11 dígitos
        data.has_bag = true; // Define has_bag como true
        data.quantity_deliveries = Math.min(data.quantity_deliveries, 2); // Limitar a 2 se necessário

        // Estrutura do objeto user
        data.user = {
          name: data.name,
          email: data.email,
          password: data.password,
        };

        // Estrutura do objeto address
        data.address = {
          postal_code: data.postal_code_1.replace(/\D/g, ""),
          address: data.address_1,
          number: data.number_1,
          city: data.city_1,
          uf: data.uf_1,
        };

        // Estrutura do objeto vehicle
        data.vehicle = {
          vehicle_type_id: Number(data.vehicle), // Converte para número
          plate: data.plate, // Adicione o campo plate
          renavam: data.renavam, // Adicione o campo renavam
        };

        const schema = Yup.object().shape({
          password: Yup.string().required("Senha obrigatória"),
          password_confirmation: Yup.string()
            .oneOf([Yup.ref("password"), null], "As senhas devem corresponder")
            .required("Confirmação de senha obrigatória"),
          name: Yup.string()
            .min(2, "Nome inválido")
            .required("Campo obrigatório"),
          nickname: Yup.string()
            .min(3, "Apelido inválido")
            .required("Campo obrigatório"),
          status: Yup.string().required("Campo obrigatório"),
          birthday: Yup.string().required("Campo obrigatório"),
          phone: Yup.string()
            .min(10, "Telefone inválido")
            .required("Campo obrigatório"),
          rg: Yup.string().required("Campo obrigatório"),
          cpf: Yup.string()
            .length(11, "O CPF deve ter 11 dígitos")
            .required("Campo obrigatório"),
          cnh: Yup.string().when("vehicle", {
            is: (val: number) => val < 3,
            then: Yup.string()
              .min(10, "A CNH deve ter 11 dígitos")
              .required("Campo obrigatório"),
          }),
          expiration_cnh_date: Yup.string().when("vehicle", {
            is: (val: number) => val < 3,
            then: Yup.string()
              .required("Campo obrigatório")
              .test(
                "is-valid-date",
                "A data de expiração do CNH deve ser uma data válida",
                (value) => {
                  if (!value) return false;
                  const date = new Date(value);
                  return !Number.isNaN(date.getTime());
                },
              ),
          }),
          mother_name: Yup.string()
            .min(3, "Nome da mãe inválido")
            .required("Campo obrigatório"),
          quantity_deliveries: Yup.number()
            .min(1, "A quantidade de entregas de motoboys deve ser no mínimo 1")
            .max(2, "A quantidade de entregas de motoboys deve ser no máximo 2")
            .required("Campo obrigatório")
            .typeError(
              "A quantidade de entregas de motoboys deve ser um número válido",
            ),
          state_id: Yup.string().required("Campo obrigatório"),
          cities: Yup.array().required("Campo obrigatório"),
          address: Yup.object().shape({
            postal_code: Yup.string().required("Campo obrigatório"),
            address: Yup.string().required("Campo obrigatório"),
            number: Yup.string().required("Campo obrigatório"),
            city: Yup.string().required("Campo obrigatório"),
            uf: Yup.string().required("Campo obrigatório"),
          }),
          vehicle: Yup.object().shape({
            vehicle_type_id: Yup.number().required("Campo obrigatório"),
            plate: Yup.string().when("vehicle", {
              is: (val: number) => val < 3,
              then: Yup.string().required("Campo obrigatório"),
            }),
            renavam: Yup.string().when("vehicle", {
              is: (val: number) => val < 3,
              then: Yup.string().required("Campo obrigatório"),
            }),
          }),
        });

        await schema.validate(data, { abortEarly: false });
        await submitDeliverymanCreateForm(data); // Chamada assíncrona para criação
      } catch (err: any) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }
      } finally {
        setShowSpinner(false);
        setIsLoading(false);
      }
    },
    [submitDeliverymanCreateForm],
  );

  useEffect(() => {
    (async () => {
      try {
        const { data: StatesData } = await api.get("/states");
        const MappedStates = StatesData.map((state: StateResponse) => {
          return {
            label: state.name,
            value: state.id,
          };
        });
        setStates(MappedStates);

        const { data: deliverymanStatusData } = await api.get(
          "/deliverymanstatus",
        );
        const MappedDeliverymanStatus = deliverymanStatusData.map(
          (status: DeliverymanStatusResponse) => {
            return {
              label: status.name,
              value: status.id,
            };
          },
        );
        setDeliverymanStatus(MappedDeliverymanStatus);

        const { data: vehicleTypesData } = await api.get("/vehicletypes");
        const MappedVehicleTypes = vehicleTypesData.map(
          (type: VehicleTypesResponse) => {
            return {
              label: type.name,
              value: type.id,
            };
          },
        );
        setVehicleTypes(MappedVehicleTypes);
      } catch (e) {
        addToast("Ocorreu um erro ao carregar dados!", {
          appearance: "warning",
          autoDismiss: true,
        });
      }
      setShowSpinner(false);
    })();
  }, [params.id, getCities, addToast]);

  return (
    <Container>
      <Sidebar />
      {showSpinner && <LoadingSpinner />}
      <ContainerMain isSidebarMaximized={isSidebarMaximized}>
        <Styled.DeliverymanContainer>
          <Styled.Link to="/gerenciar/entregadores">
            <FiArrowLeft size={20} />
            Voltar
          </Styled.Link>
          <Styled.Form
            onSubmit={verifyFormSubmit}
            ref={formRef}
            autoComplete="off"
          >
            <Styled.Strong>Dados do entregador</Styled.Strong>
            <Styled.Row>
              <Input
                type="text"
                id="name"
                name="name"
                placeholder="Nome"
                icon={FiUser}
              />
              <Input
                type="text"
                id="nickname"
                name="nickname"
                placeholder="Apelido"
                icon={FiUsers}
              />
              <Select
                id="status"
                name="status"
                placeholder="Status"
                formRef={formRef}
                options={deliverymanStatus}
                icon={FiBarChart2}
                isSearchable={false}
              />
            </Styled.Row>
            <Styled.Row>
              <Input
                type="text"
                id="birthday"
                name="birthday"
                placeholder="Data de nascimento"
                onChange={onChangeDateMask}
                icon={FiCalendar}
                maxLength={10}
              />
              <Input
                type="text"
                id="phone"
                name="phone"
                placeholder="Telefone"
                maxLength={15}
                onChange={onChangePhoneMask}
                icon={FiPhone}
              />
              <Input
                type="text"
                id="rg"
                name="rg"
                placeholder="RG"
                icon={GiIdCard}
                maxLength={12}
                onChange={onChangeRgMask}
                required
              />
            </Styled.Row>
            <Styled.Row>
              <Input
                type="text"
                id="cpf"
                name="cpf"
                placeholder="CPF"
                icon={FiColumns}
                onChange={onChangeCpfMask}
                maxLength={14}
                required
              />
              {showVehicleDetails && (
                <Input
                  type="text"
                  id="cnh"
                  name="cnh"
                  placeholder="CNH"
                  icon={GiKeyCard}
                  maxLength={11}
                />
              )}
              {showVehicleDetails && (
                <Input
                  type="text"
                  id="expiration_cnh_date"
                  name="expiration_cnh_date"
                  placeholder="Vencimento da CNH"
                  maxLength={10}
                  icon={FiCalendar}
                  onChange={onChangeDateMask}
                />
              )}
            </Styled.Row>
            <Styled.Row>
              <Input
                type="text"
                id="mother_name"
                name="mother_name"
                placeholder="Nome da mãe"
                icon={RiUserHeartLine}
              />
              <Select
                id="state_id"
                name="state_id"
                placeholder="Estado"
                formRef={formRef}
                options={states}
                icon={FiBookmark}
                onChange={(option: any) => getCities(option?.value)}
              />
            </Styled.Row>
            <Styled.Row>
              <Select
                id="cities"
                name="cities"
                placeholder="Atuação"
                formRef={formRef}
                options={cities}
                childrenStateUpdater={childrenStateUpdater}
                icon={FiNavigation}
                isMulti
              />

              <Input
                type="number"
                id="quantity_deliveries"
                name="quantity_deliveries"
                placeholder="Quantidade de Entregas Simultâneas"
                icon={RiMotorbikeFill}
                max={6}
                min={1}
              />
            </Styled.Row>
            <Styled.Strong>Endereço do entregador</Styled.Strong>
            <Styled.Row>
              <AutocompleteInput
                type="text"
                id="autocomplete_1"
                name="autocomplete_1"
                index={1}
                placeholder="Endereço"
                icon={FiCompass}
                formRef={formRef}
              />
              <Input
                type="text"
                id="complement"
                name="complement"
                placeholder="Complemento"
                icon={FiMap}
              />
              <InvisibleInput type="text" id="address_1" name="address_1" />
              <InvisibleInput type="text" id="latitude_1" name="latitude_1" />
              <InvisibleInput type="text" id="longitude_1" name="longitude_1" />
              <InvisibleInput
                type="text"
                id="postal_code_1"
                name="postal_code_1"
              />
              <InvisibleInput type="text" id="number_1" name="number_1" />
              <InvisibleInput
                type="text"
                id="neighborhood_1"
                name="neighborhood_1"
              />
              <InvisibleInput type="text" id="city_1" name="city_1" />
              <InvisibleInput type="text" id="uf_1" name="uf_1" />
            </Styled.Row>
            <Styled.Strong>Dados de segurança</Styled.Strong>
            <Styled.Row>
              <Input
                type="text"
                id="email"
                name="email"
                placeholder="Email"
                icon={FiAtSign}
              />
              <Select
                id="vehicle"
                name="vehicle"
                placeholder="Veículo"
                formRef={formRef}
                options={vehicleTypes}
                icon={AiOutlineCar}
                onChange={(option: any) =>
                  handleShowVehicleDetails(option?.value)
                }
                isSearchable={false}
              />
              {showVehicleDetails && (
                <Input
                  type="text"
                  id="renavam"
                  name="renavam"
                  placeholder="Renavam"
                  icon={BsCardHeading}
                />
              )}
            </Styled.Row>
            {showVehicleDetails && (
              <Styled.Row>
                <Input
                  type="text"
                  id="plate"
                  name="plate"
                  placeholder="Placa do veículo"
                  icon={GiMusicalKeyboard}
                  maxLength={8}
                />
              </Styled.Row>
            )}
            <Styled.Row>
              <Input
                type="password"
                id="password"
                name="password"
                placeholder="Senha"
                icon={FiUnlock}
                showPasswordOption
                required
              />

              <Input
                type="password"
                id="password_confirmation"
                name="password_confirmation"
                placeholder="Confirme a senha"
                icon={FiLock}
                showPasswordOption
                required
              />
            </Styled.Row>
            <div>
              <Button
                type="submit"
                content={isLoading ? "Carregando..." : "Salvar dados"}
                disabled={isLoading}
              />
            </div>
          </Styled.Form>
        </Styled.DeliverymanContainer>
      </ContainerMain>
    </Container>
  );
};

export default DeliverymansNew;
